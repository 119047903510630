import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, ListGroup, Row, Tab } from "react-bootstrap"
import syrfGuideListGroup from "../../../data/syrfGuideListGroup"
import ListGroupWikiItem from "../../listgroup/ListGroupWikiItem"

export default function SyRFTabs() {
  return (
    <section className="container" id="wiki">
      <Tab.Container defaultActiveKey="#00">
        <Row>
          <Col sm={4}>
            <ListGroup id="list-group">
              <ListGroupWikiItem array={syrfGuideListGroup} />
            </ListGroup>
          </Col>
          <Col sm={1}></Col>
          <Col sm={6}>
            <Tab.Content className="mt-3 mt-md-0">
              <Tab.Pane eventKey="#00">
                <h3 className="fw-bold">Boas vindas!</h3>
                <p>
                  Seja bem-vindo(a) ao Guia do Usuário SyRF, criado para lhe
                  auxiliar a usar a plataforma. Se você precisa de orientações
                  gerais sobre revisão sistemática ou meta-análise, ou se nunca
                  desenvolveu uma revisão sistemática antes, acesse o{" "}
                  <Link to="/wiki">Wiki de Revisão Sistemática</Link> criado
                  pela CAMARADES Berlin e traduzido pela CAMARADES BR.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#10">
                <h3 className="fw-bold">1 Início</h3>
                <h2>1.1 Glossário</h2>
                <h3>1.1.1 Projeto</h3>
                <p>
                  Cada projeto é específico para a sua revisão sistemática e
                  meta-análise. Faça o upload dos seus estudos desduplicados e
                  adicione etapas para analisar e anotar os seus dados.
                </p>
                <h4>1.1.1.1 Projeto público</h4>
                <p>
                  Um projeto público é aquele que pode ser visto por qualquer
                  pessoa com uma conta SyRF. Na versão atual do SyRF, outros
                  usuários podem solicitar para participar de um projeto
                  público, ou ainda podem participar de um projeto que não
                  requer aprovação prévia por parte dos proprietários do mesmo.
                </p>
                <h4>Projeto privado</h4>
                <p>
                  Um projeto que só pode ser acessado e visto por usuários SyRF
                  que são membros de tal projeto.
                </p>
                <h3>1.1.2 Protocolo</h3>
                <p>
                  Uma descrição estruturada do que se propõe a fazer na revisão
                  sistemática e meta-análise, que deverá ser finalizada antes do
                  início da revisão sistemática. Recomendamos que o protocolo
                  seja publicado ou compartilhado publicamente via{" "}
                  <a
                    target="_blank"
                    href="https://www.crd.york.ac.uk/prospero/"
                    rel="noopener noreferrer"
                  >
                    PROSPERO
                  </a>
                  .
                </p>
                <h3>1.1.3 Triagem</h3>
                <p>
                  A triagem refere-se a decidir por incluir ou excluir um estudo
                  colhido durante a busca sistemática, baseado nos critérios de
                  inclusão/exclusão definidos no protocolo. A triagem é descrita
                  em maiores detalhes na <a href="#90">Seção 9</a>.
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://camaradesuk.github.io/syrf_userguide/projectscreening.html"
                  >
                    {" "}
                  </a>
                </p>
                <h3>1.1.4 Anotações</h3>
                <p>
                  Caso deseje, você pode fazer anotações sobre os estudos,
                  catalogando ou extraindo informações relevantes deles. Esta
                  etapa de um projeto de revisão sistemática é bastante flexível
                  e, por conseguinte, você pode definir suas próprias questões
                  para guiar as anotações. As questões devem abordar todas as
                  perguntas de pesquisa a serem feitas, conforme especificado no
                  protocolo. Ainda, você pode escolher em que etapa do projeto
                  pretende responder a questões específicas. Este procedimento é
                  descrito em maiores detalhes na <a href="#90">Seção 10</a>.
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://camaradesuk.github.io/syrf_userguide/projectannotation.html"
                  >
                    Veja mais aqui
                  </a>
                  .
                </p>
                <h3>1.1.5 Extração de dados</h3>
                <p>
                  Onde se extraem dados de gráficos ou tabelas sob a forma de
                  médias/medianas e erro correspondente.
                </p>
                <h3>1.1.6 Experimento</h3>
                <p>
                  Um experimento refere-se a qualquer agrupamento de coortes
                  submetidos a um mesmo experimento e que podem ser comparados
                  uns aos outros.
                </p>
                <h3>1.1.7 Coorte</h3>
                <p>
                  Coorte refere-se a um grupo de animais - mesma espécie,
                  linhagem, fonte, comorbidades (se aplicável) - em que todos
                  passam pelos mesmos procedimentos e tratamentos e podem ser
                  comparados com outros coortes. Por exemplo, um experimento
                  pode envolver os seguintes coortes:
                </p>
                <p>Exemplo de Experimento 1:</p>
                <p>• Coorte 1: Tratamento (Modelo de Doença + Tratamento)</p>
                <p>• Coorte 2: Placebo</p>
                <p>• Coorte 3: Controle (Modelo de Doença + Veículo)</p>
                <p>Exemplo de Experimento 2:</p>
                <p>• Coorte 1: Controle (Modelo de Doença + Veículo)</p>
                <p>
                  • Coorte 2: Tratamento 1 (Modelo de Doença + Tratamento 1)
                </p>
                <p>
                  • Coorte 3: Tratamento 2 (Modelo de Doença + Tratamento 2)
                </p>
                <p>
                  Em projetos SyRF, coortes são criados através da combinação de
                  modelos de doença e detalhes de tratamento. (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://camaradesuk.github.io/syrf_userguide/projectannotation.html"
                  >
                    Veja mais aqui
                  </a>
                  ).
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#20">
                <h3 className="fw-bold">2 Crie uma Conta</h3>
                <p>
                  É necessário criar uma conta gratuita para criar ou participar
                  de projetos.
                </p>
                <p>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://syrf.org.uk"
                  >
                    Clique aqui para criar uma nova conta SyRF
                  </a>
                </p>
                <p>
                  A criação de uma conta garante a segurança dos seus dados e
                  permite que o administrador de cada projeto veja quem tem
                  acesso aos dados do projeto. Saiba mais sobre a nossa [
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://auth.syrf.org.uk/Privacy"
                  >
                    <u>Política de Gestão e Compartilhamento de Dados</u>
                  </a>
                  <u>]</u>
                  aqui.
                </p>
                <p>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://syrf.org.uk/faq"
                  >
                    <strong>
                      Não encontrei um e-mail da SyRF na minha caixa de entrada
                    </strong>
                  </a>
                </p>
                <p>
                  Depois de criar uma conta e fazer login na plataforma SyRF,
                  você poderá acessar projetos públicos ou criar um novo projeto
                  através da aba Projects.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#30">
                <h3 className="fw-bold">3 Participar de um Projeto</h3>
                <p>
                  A aba Projects na SyRF mostra todos os projetos em que você é
                  membro, bem como todos os projetos públicos.
                </p>
                <p>
                  Para participar de um projeto, você precisa clicar em “Request
                  to join” na homepage do projeto. Na versão atual da SyRF,
                  todos os usuários que solicitam a participação em um projeto
                  recebem acesso a ele automaticamente, juntamente com a
                  atribuição de Revisor do projeto.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/syrf/Fig_Request_to_join.png"
                    alt="Fig_Request_to_join"
                  />
                </p>
                <p>
                  Se você não deseja que outros usuários participem do seu
                  projeto, é melhor configurá-lo como um projeto privado.
                </p>
                <p>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://syrf.org.uk/faq"
                  >
                    <strong>
                      Meus colaboradores não conseguem visualizar meu projeto
                    </strong>
                  </a>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#40">
                <h3 className="fw-bold">4 Criar Novo Projeto</h3>
                <p>
                  Novos projetos podem ser criados através da aba Projects.
                  Insira os detalhes do seu projeto no formulário pop-up que
                  aparecerá na tela.
                </p>
                <p>
                  Como parte da criação, você terá que especificar os critérios
                  de inclusão/exclusão do seu projeto. Tais critérios terão que
                  ser previamente especificados no seu protocolo.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/syrf/Fig_Create_a_project.png"
                    alt="Fig_Create_a_project"
                  />
                </p>
                <p>
                  Após ter criado o projeto, você poderá acompanhar o seu
                  progresso através da página Project Details, no lado direito
                  da tela.
                </p>
                <p>
                  Você receberá automaticamente a atribuição de Administrador do
                  Projeto em qualquer projeto que criar.
                </p>
                <h2>4.1 Projetos com múltiplos critérios de triagem</h2>
                <p>
                  No momento, é possível ter apenas um conjunto de critérios de
                  inclusão/exclusão por projeto SyRF. Caso você queira aplicar
                  múltiplas etapas de triagem na sua revisão sistemática, como
                  por exemplo uma etapa visando título e resumo, seguida de uma
                  etapa visando o texto na íntegra, é possível exportar seus
                  estudos incluídos no projeto SyRF seguindo a primeira etapa de
                  triagem e fazendo upload deles para um novo projeto SyRF a fim
                  de completar a segunda etapa.
                </p>
                <p>
                  <a href="mailto:syrf.info@ed.ac.uk">
                    <u>Entre em contato conosco</u>
                  </a>{" "}
                  <u></u> <u>(mailto:syrf.info@ed.ac.uk)</u> para receber um
                  link para o App Shiny, que permite a exportação de seus dados.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#50">
                <h3 className="fw-bold">5 Atribuições do usuário SyRF </h3>
                <p>
                  Existem duas atribuições que o usuário SyRF pode receber:
                  Administrador do Projeto (admin) e Revisor. As atribuições são
                  específicas de cada projeto, ou seja, você pode ser o
                  Administrador em alguns dos projetos e Revisor em outros.
                </p>
                <p>
                  Você receberá a atribuição de Administrador do Projeto
                  automaticamente em qualquer projeto que criar. Administradores
                  podem editar projetos.
                </p>
                <p>
                  Usuários SyRF que solicitarem participação em um projeto
                  receberão a atribuição de Revisor automaticamente. Os
                  Revisores têm acesso às etapas de triagem e anotação.
                </p>
                <h2>5.1 Alterando a atribuição de um usuário</h2>
                <p>
                  Quando usuários ingressam em um projeto, eles automaticamente
                  recebem a atribuição de Revisor.
                </p>
                <p>
                  Apenas Administradores podem alterar as atribuições dadas aos
                  usuários. Na lista de membros do projeto, é possível colocar
                  outros membros como administradores selecionando a checkbox de
                  Administrador.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/syrf/Fig_Change_user_role.png"
                    alt="Fig_Change_user_role"
                  />
                </p>
                <p>
                  Se você recebeu a atribuição de Revisor, mas acredita que
                  deveria ser um Administrador, entre em contato com um dos
                  Administradores do Projeto para que a alteração seja feita.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#60">
                <h3 className="fw-bold">6 Navegando pelo Projeto</h3>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/syrf/Fig_Project_Nav.png"
                    alt="Fig_Project_Nav"
                  />
                </p>
                <h2>6.1 Visão Geral do Projeto</h2>
                <p>
                  Com o modo Project Overview é possível expandir a descrição de
                  cada etapa para checar o progresso feito durante os processos
                  de triagem e anotação de uma determinada etapa do projeto.
                </p>
                <h2>6.2 Detalhes do Projeto</h2>
                <p>
                  Na página Project Details é possível ter uma visão geral do
                  projeto.
                </p>
                <p>
                  No centro da página é apresentada uma etapa de triagem com os
                  critérios de inclusão e exclusão que foram adicionados pelo
                  Administrador durante a criação do projeto. Clique no botão
                  ‘Review’ para acessar uma determinada etapa. Caso você seja um
                  dos Administradores do projeto, você poderá também adicionar
                  novas etapas ao projeto.
                </p>
                <p>
                  No lado direito da página Project Overview estão presentes as
                  seguintes informações:
                </p>
                <p>
                  • Contact (contato) - o email de contato do administrador do
                  projeto
                </p>
                <p>
                  • Protocol url (URL do protocolo) - um link que redireciona
                  para a publicação do protocolo da revisão sistemática
                </p>
                <p>
                  • Keywords (palavras-chave) - todas as palavras-chave
                  associadas ao projeto
                </p>
                <p>
                  • Systematic searches (buscas sistemáticas) - detalhes dos
                  estudos adicionados ao projeto
                </p>
                <p>
                  • Project visibility (acesso ao projeto) - se é público ou
                  privado
                </p>
                <p>
                  • Creation date (data de criação) - a data em que o projeto
                  foi criado
                </p>
                <p>• Members (membros) - uma lista com os membros do projeto</p>
                <p>
                  Os detalhes do projeto podem ser editados clicando no ícone de
                  configuração (engrenagem) que aparece ao lado de cada seção.
                </p>
                <h2>6.3 Estudos</h2>
                <p>
                  Você pode visualizar os detalhes dos estudos que arquivou no
                  SyRF na aba Studies. Para aprender como fazer o upload dos
                  estudos ou buscas sistemáticas na plataforma, visite a
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://camaradesuk.github.io/syrf_userguide/systematicSearch.html"
                  >
                    Seção 7
                  </a>
                </p>
                <h2>6.4 Etapas</h2>
                <p>
                  Você pode iniciar os processos de triagem e anotação em uma
                  etapa clicando no nome dela (ao lado esquerdo da tela) e
                  depois em ‘Review’. Observe que, no caso de o Administrador do
                  projeto não ter feito o upload de nenhuma busca sistemática,
                  não será possível que você acesse uma etapa.
                </p>
                <h2>6.5 Configurações do Projeto</h2>
                <p>
                  Apenas Administradores têm permissão para editar as
                  configurações do projeto.
                </p>
                <h3>6.5.1 Configurações gerais</h3>
                <p>
                  Nas configurações gerais do projeto, os Administradores podem
                  editar:
                </p>
                <p>• Nome do projeto</p>
                <p>• Descrição do projeto</p>
                <p>• Permissão de acesso ao projeto</p>
                <p>• E-mail para contato</p>
                <p>• URL do protocolo</p>
                <p>• Palavras-chave</p>
                <h4>6.5.1.1 Deletando um projeto</h4>
                <p>
                  Os projetos podem ser deletados através das configurações
                  gerais. Observe que não é possível recuperar os dados de um
                  projeto que foi deletado.
                </p>
                <h3>6.5.2 Membros &amp; grupos</h3>
                <p>
                  Administradores podem alterar as funções de usuários na página
                  de configurações de Members &amp; groups. Para tornar um
                  usuário um novo Administrador do projeto, clique na checkbox
                  próxima ao seu nome. Clique novamente (deixando de selecionar
                  a checkbox) para torná-lo Revisor. As mudanças são salvas
                  automaticamente.
                </p>
                <h3>6.5.3 Buscas sistemáticas</h3>
                <p>
                  Administradores do projeto podem visualizar buscas
                  sistemáticas que foram previamente arquivadas na plataforma,
                  ou fazer o upload de novas buscas nesta página. Para maiores
                  informações, visite a
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://camaradesuk.github.io/syrf_userguide/systematicSearch.html"
                  >
                    Seção 7
                  </a>
                </p>
                <h3>6.5.4 Questões guia</h3>
                <p>
                  Administradores podem adicionar ou excluir questões para guiar
                  as anotações do projeto nesta página. As questões, por sua
                  vez, podem ser atribuídas a etapas específicas. Para maiores
                  informações, visite a
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://camaradesuk.github.io/syrf_userguide/projectannotation.html"
                  >
                    Seção 10
                  </a>
                </p>
                <h3>6.5.5 Configurações de etapas</h3>
                <p>
                  Nesta página, Administradores podem adicionar e editar etapas.
                </p>
                <p>
                  Você pode escolher quais procedimentos adicionar a cada etapa,
                  como triagem, anotações e/ou extração de dados. Se optar por
                  ambos triagem e anotações, terá que decidir como combiná-los
                  de acordo com as seguintes opções: Todos os estudos requerem
                  triagem, mas as anotações são opcionais - Todos os estudos
                  requerem anotações, mas triagens são opcionais - Todos os
                  estudos requerem ambos triagem e anotações. [
                  <em>
                    All studies require screening, but annotation is optional -
                    All studies require annotation, but screening is optional -
                    All studies require both screening and annotation
                  </em>
                  ].
                </p>
                <h4>6.5.5.1 Seleção de questões guia</h4>
                <p>
                  Administradores podem selecionar quais das questões criadas na
                  página de configurações de Questões guias serão adicionadas em
                  cada etapa. Clique no botão ‘Edit project questions’ para ser
                  redirecionado à página de questões guia.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#70">
                <h3 className="fw-bold">7 Fazendo o upload de uma Busca Sistemática</h3>
                <h2>7.1 Desduplicando sua busca sistemática</h2>
                <p>
                  Se a sua busca por estudos foi feita usando múltiplas bases de
                  dados, ela provavelmente contará com estudos duplicados. No
                  momento, a SyRF não é capaz de fazer a desduplicação de
                  estudos, portanto isto terá de ser feito antes de fazer o
                  upload dos estudos na SyRF. Você pode desduplicar os estudos
                  automaticamente usando a ferramenta de desduplicação da
                  CAMARADES –
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://camarades.shinyapps.io/RDedup/"
                  >
                    CAMARADES deduplication tool
                  </a>
                </p>
                <h2>7.2 Fazendo o upload de arquivos</h2>
                <p>
                  Os Administradores podem fazer upload de uma busca sistemática
                  em seus projetos ao selecionar Systematic Searches nas
                  configurações do projeto e clicando em “Upload New Systematic
                  Search”.
                </p>
                <p>As buscas sistemáticas podem ser carregadas nos formatos:</p>
                <p>• EndNote XML</p>
                <p>• Comma separated value (CSV)</p>
                <p>
                  No momento, a SyRF aceita arquivos EndNote Desktop XML.
                  Arquivos EndNote Web XML não são suportados pela plataforma.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/syrf/Fig_Upload_search.png"
                    alt="Fig_Upload_search"
                  />
                </p>
                <h3>7.2.1 Usando EndNote (XML)</h3>
                <p>
                  <em>
                    Sem decisões de triagem (upload de triagens não suportado)
                  </em>
                </p>
                <p>
                  1. Importe todas as referências a serem analisadas para o
                  EndNote
                </p>
                <p>
                  2. Encontre os PDFs dos textos integrais selecionando todas as
                  referências (Ctrl+A) e clique em References -&gt; Find Full
                  Text
                </p>
                <p>
                  3. Selecione (Ctrl+A) todas as referências e clique em File
                  -&gt; Export (OBS.: se não selecionar todas as referências,
                  apenas a primeira da lista será exportada)
                </p>
                <p>4. Altere o tipo de arquivo para XML</p>
                <p>
                  5. Nomeie e salve o seu arquivo XML, que então estará pronto
                  para o upload na base de dados de triagem SyRF
                </p>
                <h4>7.2.Fazendo o upload de uma exportação do Zotero</h4>
                <p>
                  Observe que você não pode utilizar a opção de exportação
                  'EndNote XML' no Zotero para carregar um arquivo EndNote para
                  a SyRF. Se estiver usando o Zotero para controlar as suas
                  referências de estudo, exporte como um arquivo CSV e siga as
                  instruções de upload para CSV.
                </p>
                <p>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://syrf.org.uk/faq"
                  >
                    <strong>
                      Estou tentando carregar um arquivo EndNote XML que foi
                      criado importando de um local que não seja uma base de
                      dados eletrônica
                    </strong>
                  </a>
                </p>
                <h3>7.2.2 Planilhas (*.csv aceito)</h3>
                <p>
                  <em>É possível que inclua decisões de triagem </em>
                </p>
                <p>
                  1. Você pode carregar as referências de estudo como uma busca
                  sistemática a partir de uma planilha (ex. Documento Microsoft
                  Excel)
                </p>
                <p>
                  2. O seu arquivo precisa ter os seguintes títulos no cabeçalho
                  da coluna para que o upload funcione:
                </p>
                <p>– Title (título)</p>
                <p>– Authors (autores)</p>
                <p>– Publication Name (nome da publicação)</p>
                <p>– Alternate Name (nome alternativo)</p>
                <p>– Abstract (resumo)</p>
                <p>– Url</p>
                <p>– Author Address (endereço do autor)</p>
                <p>– Year (ano)</p>
                <p>– DOI</p>
                <p>– Reference Type (tipo de referência)</p>
                <p>– Keywords (palavras-chave)</p>
                <p>– PDF Relative Path</p>
                <p>
                  Aqui você encontra para download um modelo com os [
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://syrf-test.co.uk/assets/pdfs/Systematic_search_instructions.html"
                  >
                    títulos corretos do cabeçalho e exemplos de dados
                  </a>
                  ].
                </p>
                <p>
                  3. Mesmo que você não tenha as informações de todas as colunas
                  especificadas, <strong>elas precisam estar no arquivo</strong>{" "}
                  para garantir que o upload funcione. A SyRF aceita campos
                  vazios para qualquer uma dessas variáveis.
                </p>
                <p>
                  4. Se você deseja fazer o upload de <strong>triagens </strong>
                  já realizadas fora da plataforma SyRF junto com a lista dos
                  estudos, deve<strong>ad</strong>{" "}
                  <strong>icionar colunas separadas para cada usuário</strong> e
                  o wizard da SyRF vai permitir que você selecione quais títulos
                  das colunas no arquivo correspondem a cada membro.
                </p>
                <p>
                  5. As decisões tomadas na triagem devem ser representadas nas
                  colunas com os valores de <strong>1 </strong>{" "}
                  <strong>para inclusão e 0 para exclusão.</strong>
                </p>
                <p>
                  6. O arquivo deve conter <strong>apenas</strong> as colunas
                  citadas acima e colunas especificadas com as decisões das
                  triagens. Se alguma coluna estiver faltando ou tiver sido
                  adicionada (não específica para triagem) o upload irá falhar.
                </p>
                <p>
                  7. Os arquivos devem ser salvos como CSV - Comma delimited
                  (*.csv). Isso pode ser feito no excel ao clicar em ‘Salvar
                  como’.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/syrf/save_csv.png"
                    alt="save_csv"
                  />
                </p>
                <h2>7.3 Fazendo o upload de PDFs na íntegra</h2>
                <p>
                  Se você for precisar de PDFs com os textos na íntegra de cada
                  um dos estudos em qualquer etapa do projeto SyRF, é importante
                  que você já os tenha antes de fazer o upload do arquivo de
                  busca.
                </p>
                <p>
                  Certifique-se de que no arquivo da busca sistemática que você
                  carregou (planilha csv/tsv ou Endnote XML), a coluna “PDF
                  Relative Path” contém links relativos (ou seja, relativo à
                  raiz da pasta que nos enviou) para os seus PDFs para cada
                  registro.
                </p>
                <p>
                  Você precisará{" "}
                  <a href="mailto:syrf.info@ed.ac.uk">nos contatar</a> com o
                  nome do seu projeto, uma pasta contendo os PDFs (enviada via
                  Google Drive ou similar) e um arquivo CSV contendo o caminho
                  para cada PDF e o título ou ID SyRF de cada estudo, para que
                  possamos encontrar os PDFs correspondentes para cada estudo na
                  SyRF.
                </p>
                <p>
                  Faremos o upload desses PDFs na base de dados da SyRF database
                  e eles poderão ser visualizados durante a triagem.
                </p>
                <h3>7.3 Nomes dos arquivos em PDF</h3>
                <p>
                  Evite usar caracteres inválidos (ex.: , &lt; &gt; : " \ / | ?
                  *) nos nomes dos arquivos, já que isso pode causar problemas.
                  Como padrão, softwares como o EndNote usam informações de
                  autor e título para nomear arquivos, o que às vezes pode fazer
                  com que caracteres inválidos sejam inseridos no nome do
                  arquivo PDF. Você pode alterar o padrão de nomear PDFs de
                  acordo com informações de outra coluna, tal como a de
                  RecordID. Qualquer que seja a coluna com a qual escolher
                  nomear os seus PDFs, os dados devem ser únicos.
                </p>
                <p>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://syrf.org.uk/faq"
                  >
                    <strong>
                      Estou conduzindo um processo de triagem em duas etapas e
                      preciso adicionar PDFs apenas para os estudos incluídos na
                      triagem dos textos na íntegra
                    </strong>
                  </a>
                </p>
                <h2>7.4 Visualizar estudos do projeto</h2>
                <p>
                  Você pode visualizar os estudos que fazem parte do projeto
                  clicando na aba ‘Studies’ e então em ‘All Studies’ no lado
                  esquerdo do seu painel de navegação. Essa página irá mostrar
                  todos os estudos que foram adicionados ao seu projeto.
                </p>
                <h2>7.5 Deletando buscas sistemáticas</h2>
                <p>
                  Você consegue deletar uma busca sistemática na SyRF se
                  precisar. Entretanto, esteja ciente de que se você usou a SyRF
                  para conduzir triagens ou anotações dos estudos, ao deletar a
                  busca sistemática você também estará deletando as informações
                  das triagens e das anotações.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#80">
                <h3 className="fw-bold">8 Etapas do Projeto</h3>
                <h2>8.1 O que são as etapas?</h2>
                <p>
                  As etapas são seções do seu projeto SyRF que têm o intuito de
                  realizar procedimentos como triagem e anotações dos dados. É
                  possível separar a realização de triagem e anotações em etapas
                  diferentes, ou realizá-las na mesma etapa.
                </p>
                <h2>8.2 Adicione uma etapa</h2>
                <p>
                  Você pode adicionar uma etapa através do lado esquerdo da
                  barra de navegação do projeto, ou clicando em “add stage” na
                  página de visão geral do projeto.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/syrf/Fig_Add_stage.png"
                    alt="Fig_Add_stage"
                  />
                </p>
                <p>
                  Após adicionar uma etapa, você pode incluí-la no projeto
                  mudando as configurações para “Active” no canto direito do
                  topo da página.
                </p>
                <h2>8.3 Etapa com triagem</h2>
                <p>
                  Ao adicionar uma triagem a uma etapa, você pode analisar os
                  estudos que carregou para a plataforma SyRF de acordo com os
                  critérios de inclusão/exclusão descritos na criação do
                  projeto. No momento, como os critérios de inclusão/exclusão
                  são definidos a nível do projeto, só é possível fazer a
                  triagem com base em apenas um conjunto de critérios.
                </p>
                <h2>8.4 Etapa com anotações</h2>
                <p>
                  Uma etapa com anotações lhe permite selecionar dados de um
                  estudo, de acordo com as questões pré-definidas no protocolo
                  da revisão sistemática. A SyRF tem uma ferramenta de
                  construção de perguntas para que você desenvolva as questões
                  para o seu projeto.
                </p>
                <p>
                  Depois de ter desenvolvido todas as questões que irão guiar as
                  anotações, é necessário que você especifique em qual etapa
                  cada pergunta deve ser respondida. Isto pode ser feito antes
                  de iniciar a triagem ou depois de finalizá-la.
                </p>
                <p>
                  Para adicionar perguntas a uma determinada etapa, acesse a
                  seção ‘Stages’ na homepage do projeto e clique em ‘Enter
                  Stage’, e em seguida em ‘Stage Desing’ para editá-la.
                </p>
                <h3>8.4.1 Extração de dados</h3>
                <p>
                  Para extrair dados de gráficos nos estudos da sua revisão
                  sistemática é necessário ativar a extração de dados em
                  conjunto com as anotações naquela etapa.
                </p>
                <h2>8.5 Triagem e anotações na mesma etapa</h2>
                <p>
                  Se você deseja conduzir triagem e anotações ao mesmo tempo,
                  precisa ter essas duas funcionalidades ativas (por exemplo,
                  mesmo que você já tenha conduzido a triagem em uma etapa
                  diferente, pode ser interessante ter essa opção ativada no
                  caso de haver necessidade de excluir algum estudo
                  posteriormente depois de ter lido o texto na íntegra).
                </p>
                <p>
                  Você pode selecionar as questões que deseja incluir nesta
                  etapa clicando na caixa (que fica ao lado das perguntas)
                  daquelas que forem relevantes.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#90">
                <h3 className="fw-bold">9 Triagem </h3>
                <h2>9.1 Triagem usando SyRF</h2>
                <p>
                  Você pode fazer a triagem dos estudos que fazem parte do seu
                  projeto SyRF com relação aos critérios de inclusão/exclusão,
                  definidos no protocolo da sua revisão sistemática e que foram
                  especificados na criação do projeto, criando uma etapa de
                  triagem na SyRF.
                </p>
                <p>
                  Ao iniciar a revisão em uma etapa com a função de triagem
                  ativada, você verá na tela o título e o resumo de um estudo
                  aleatório encontrado nas buscas sistemáticas que você carregou
                  para a plataforma. Caso você também tenha feito o upload de
                  PDFs, existe um botão que lhe permite a visualização dos
                  mesmos.
                </p>
                <p>
                  Use os botões de incluir e excluir para registrar se um estudo
                  atende ou não aos critérios de inclusão/exclusão. Caso você
                  esteja incerto sobre um estudo e queira voltar a analisá-lo
                  mais tarde, clique em ‘Next’ para pular o presente estudo e
                  começar a analisar outro.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/syrf/Fig_Screening.png"
                    alt="Fig_Screening"
                  />
                </p>
                <p>
                  Assim que decidir por incluir ou excluir um estudo, ele será
                  registrado como ‘completed’ pela SyRF e não será mostrado
                  novamente para você. Caso acredite que tenha cometido um erro,
                  clique no botão de voltar no seu navegador para retornar ao
                  estudo anterior e conduzir a triagem novamente.
                </p>
                <h3>9.1.1 Estudos indisponíveis para triagem</h3>
                <p>
                  Não serão mostrados para você os estudos que já foram
                  suficientemente analisados por outros revisores do projeto.
                  Ademais, você poderá ver quantos estudos já foram
                  suficientemente analisados por outros revisores, marcados como
                  ‘Unavailable’, na barra de progresso da tela de triagem.
                  Informações sobre como configurar o número de revisores
                  necessários para marcar um estudo como suficientemente
                  analisado podem ser encontradas na próxima seção, ‘Número de
                  Examinadores’.
                </p>
                <h2>9.2 Número de Revisores</h2>
                <p>
                  Como padrão, a SyRF prevê que cada estudo seja analisado por
                  dois revisores independentes, com eventuais discordâncias
                  resolvidas por um terceiro revisor, o que significa que é
                  necessário ter pelo menos três examinadores no projeto. A SyRF
                  irá checar quais estudos apresentam discordâncias e eles
                  automaticamente ficarão disponíveis a uma terceira pessoa.
                </p>
                <p>
                  Se você é um estudante conduzindo um projeto sozinho e não
                  conta com outros Revisores, pode fazer alterações em Stage
                  Settings para permitir uma única análise (triagem).
                  Recomendamos que isso seja feito apenas em projetos conduzidos
                  por estudantes, não em revisões sistemáticas com intenções de
                  serem publicadas em revistas científicas.
                </p>
                <p>
                  Se você deseja resolver discordâncias manualmente, também pode
                  acessar Stage Settings para fazer tal alteração. Observe que
                  esta opção não é recomendada e você terá que contatar nossa
                  Assistência (Help Desk) que irá lhe auxiliar a acessar seus
                  dados para a resolução manual.
                </p>
                <p>
                  <strong>
                    Meu projeto tem apenas dois examinadores, como posso
                    visualizar as decisões feitas durante
                  </strong>
                  <strong>a triagem?</strong>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://syrf.org.uk/faq"
                  >
                    Confira o FAQ
                  </a>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#100">
                <h3 className="fw-bold">10 Questões guia para as anotações </h3>
                <p>
                  No protocolo da sua revisão sistemática, você precisa
                  especificar certas informações que deseja extrair de cada um
                  dos estudos, por exemplo ‘Os animais foram randomizados para
                  grupos experimentais?’ ou ‘Qual concentração de medicamento
                  foi usada no tratamento?’. Na SyRF você consegue fazer
                  anotações acerca dessas informações sobre os estudos
                  analisados usando as questões guia.
                </p>
                <p>
                  Para criar questões guia a serem usadas em uma etapa do seu
                  projeto, acesse 'Question design’ em ‘Project Settings’ no
                  lado esquerdo da barra de navegação do projeto.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/syrf/Fig_Question_design.png"
                    alt="Fig_Question_design"
                  />
                </p>
                <h2>10.1 Criando questões guia</h2>
                <h3>10.1.1 Editando questões</h3>
                <p>
                  No momento, as questões podem ser apenas adicionadas ou
                  deletadas. Questões individuais não podem ser editadas e nem
                  ter sua ordem alterada. Isso acontece, primordialmente, devido
                  a estrutura da nossa base de dados, e uma ferramenta de edição
                  será implementada em atualizações futuras.
                </p>
                <h2>10.2 Categorias das questões</h2>
                <p>
                  As questões guia para anotações são separadas nas seguintes
                  categorias dependendo de seu foco:
                </p>
                <h3>10.2.1 Questões a nível do estudo</h3>
                <p>
                  Quaisquer questões que sejam relevantes ao estudo como um
                  todo.
                </p>
                <p>
                  Ex: Os autores disponibilizam um protocolo do estudo que você
                  consegue visualizar? (selecione Sim ou Não)
                </p>
                <h3>10.2.2 Questões sobre Indução do Modelo de Doença</h3>
                <p>
                  Ao adicionar uma questão, você precisará selecionar se ela se
                  refere aos <em>Control animals</em> (animais controle),
                  Non-control animals (animais modelo), ou ambos.
                </p>
                <h4>10.2.2.1 Questões de control animals</h4>
                <p>
                  Defina questões que são específicas para o grupo de controle.
                </p>
                <p>
                  Ex.: Os animais controle foram submetidos a uma cirurgia Sham?
                  (selecione Sim ou Não)
                </p>
                <h4>10.2.2.2 Questões de non-control animals</h4>
                <p>Defina questões que são específicas para o grupo modelo.</p>
                <p>
                  Ex. Que tipo de cirurgia foi conduzida para induzir o modelo?
                  (Lista suspensa com opções definidas)
                </p>
                <h4>10.2.2.3 Ambos</h4>
                <p>
                  Defina questões que são relevantes para ambos animais controle
                  e animais modelo (<em>Model control</em> e{" "}
                  <em>Model animals</em>)
                </p>
                <p>
                  Ex.: Qual anestesia foi utilizada em ambas cirurgia modelo e
                  cirurgia placebo? (Lista suspensa com opções definidas)
                </p>
                <h3>10.2.3 Questões do tratamento</h3>
                <p>
                  Ao adicionar uma questão, você precisará selecionar se ela se
                  refere aos <em>Control animals</em> (animais controle),
                  Non-control animals (animais tratados), ou ambos.
                </p>
                <h4>10.2.3.1 Questões de control animals</h4>
                <p>
                  Defina questões que são específicas ao tratamento controle.
                </p>
                <p>
                  Ex.: Qual o veículo dado aos animais controle? (Lista suspensa
                  com opções definidas)
                </p>
                <h4>10.2.3.2 Questões de non-control animals</h4>
                <p>Defina questões que são específicas ao tratamento.</p>
                <p>
                  Ex.: Especifique a dose do medicamento do tratamento dado em
                  mg/kg (Caixa de entrada decimal)
                </p>
                <h4>10.2.3.3 Ambos</h4>
                <p>
                  Defina questões que são relevantes para ambos Treatment
                  control e Treatment animals.
                </p>
                <p>
                  Ex.: Qual via de administração do medicamento é usada no
                  experimento? (Lista suspensa com opções definidas)
                </p>
                <h3>10.2.4 Questões para a avaliação dos resultados</h3>
                <p>
                  Defina questões pertinentes a cada procedimento de avaliação
                  dos resultados dos estudos.
                </p>
                <p>
                  Ex.: Qual é o teste comportamental utilizado para medir o
                  resultado? (Lista suspensa com opções definidas)
                </p>
                <h3>10.2.5 Questões a nível das coortes</h3>
                <p>
                  Defina questões pertinentes a cada coorte (grupo experimental)
                  dos estudos.
                </p>
                <p>
                  Ex.: De que sexo são os animais inclusos na coorte? (Lista com
                  as opções masculino, feminino, ambos, desconhecido)
                </p>
                <p>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://syrf.org.uk/faq"
                  >
                    Tenho coortes com comorbidades e não sei como
                    diferenciá-las.
                  </a>
                </p>
                <h3>10.2.6 Questões sobre os experimentos</h3>
                <p>
                  Defina questões pertinentes a cada experimento dos estudos.
                </p>
                <p>
                  Ex.: Foi implementado um período de habituação? (selecione Sim
                  ou Não)
                </p>
                <h2>10.3 Criando uma nova questão</h2>
                <p>
                  Para adicionar uma questão, é só clicar no botão ‘+’ ao lado
                  da categoria que você deseja incluí-la.
                </p>
                <p>
                  Ao adicionar uma questão, você pode inserir os seguintes
                  detalhes:
                </p>
                <p>Nome da questão</p>
                <p>
                  Uma descrição da questão, que será exibida juntamente com a
                  questão na SyRF
                </p>
                <p>
                  Se a questão aceita apenas uma resposta ou múltiplas respostas
                </p>
                <p>Se a questão é opcional ou obrigatória</p>
                <p>
                  O tipo de resposta que a questão aceita (ex. texto, valores
                  inteiros, valores decimais)
                </p>
                <p>
                  O tipo de questão (ex. com lista suspensa, com caixas de
                  verificação)
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/syrf/Fig_Question_dialogue.png"
                    alt="Fig_Question_dialogue"
                  />
                </p>
                <h3>10.3.1 Tipos de questão</h3>
                <p>Existem 6 tipos de questão na SyRF:</p>
                <p>• Lista suspensa (Dropdown lists)</p>
                <p>• Lista suspensa com autocomplemento (Autocomplete lists)</p>
                <p>• Botões de opções (Radio buttons)</p>
                <p>• Lista de verificação (Checklists)</p>
                <p>• Caixas de seleção (Check boxes)</p>
                <p>• Caixa de diálogo (Input boxes)</p>
                <p>
                  Recomendamos que ao utilizar listas de verificação ou caixas
                  de seleção, defina o status padrão da caixa de seleção para
                  ‘indeterminate’.
                </p>
                <h3>10.3.2 Possibilidade de respostas múltiplas</h3>
                <p>
                  Se você optar por permitir respostas múltiplas, precisará
                  decidir se quer que elas sejam separadas em anotações
                  diferentes. Isto refere-se à forma como os dados serão
                  apresentados no arquivo dos dados de saída.
                </p>
                <p>
                  Optar por separar respostas múltiplas em diferentes anotações
                  significa que, no arquivo de saída, as respostas ficarão em
                  linhas diferentes. Optar por não separá-las significa que elas
                  irão aparecer na mesma linha, separadas por um sinal de ponto
                  e vírgula (;).
                </p>
                <p>
                  Se houver perguntas aninhadas, é possível que seja melhor
                  optar por separar as respostas em anotações diferentes, já que
                  assim a hierarquia das respostas condicionadas será
                  apresentada corretamente no arquivo de saída. Por exemplo, em
                  uma questão sobre a avaliação dos resultados (outcome
                  assessment), tal como ‘Quais foram os testes comportamentais
                  utilizados?’, respostas múltiplas seriam permitidas e você
                  poderia optar por separá-las em anotações diferentes, pois
                  isso permitiria seguir com mais questões relacionadas a um
                  teste comportamental específico.
                </p>
                <h3>10.3.3 Perguntas aninhadas e perguntas condicionais</h3>
                <p>
                  As perguntas podem estar aninhadas para permitir que exista
                  uma hierarquia de informações condicionais (isto é, as
                  perguntas serão ativadas dependendo das respostas de perguntas
                  anteriores).
                </p>
                <p>
                  Para cada pergunta você pode optar por adicionar perguntas
                  relacionadas, onde as respostas estarão condicionadas à
                  resposta da pergunta anterior.
                </p>
                <p>
                  Ex.: “Que tipo de modelo foi utilizado?” (Lista suspensa com
                  as opções: Farmacológico ou Cirúrgico)
                </p>
                <p>
                  Você pode, então, adicionar uma pergunta relacionada
                  selecionando “Add Related” (Adicionar relacionada), e no
                  formulário “conditionally display based on parent question”
                  (exibição condicional com base na pergunta inicial).
                </p>
                <p>
                  Ex.: “Que medicamento foi utilizado?” (Lista suspensa com
                  opções de diferentes drogas)
                </p>
                <p>
                  Você pode, então, fazer mais perguntas relacionadas, clicando
                  nesta pergunta e selecionando 'Add Related’ (Adicionar
                  relacionada), perguntando para cada droga selecionada: "Qual é
                  a dose e a via de administração?". Se for selecionado
                  Cirúrgico, as seguintes perguntas relacionadas podem ser
                  feitas: "Qual foi a anestesia utilizada?" ou "Qual foi o local
                  da lesão?".
                </p>
                <p>Essas perguntas serão aninhadas sob a pergunta anterior.</p>
                <h2>10.4 Adicionando questões às etapas</h2>
                <p>
                  Assim que suas questões estiverem prontas, para permitir que
                  elas sejam mostradas para os Revisores, você precisa
                  adicioná-las à etapa de anotações clicando em ‘Stage Settings’
                  e então no nome da etapa que você quer adicionar a pergunta,
                  então selecionando as perguntas a serem adicionadas.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#110">
                <h3 className="fw-bold">11. Sobre</h3>
                <h2>11.1 CAMARADES</h2>
                <p>
                  Desde 2005, o grupo Abordagem Colaborativa à Meta-análise e
                  Revisão de Dados de Animais de Estudos Experimentais
                  <em>
                    Collaborative Approach to Meta-Analysis and Review of Animal
                    Data from Experimental Studies
                  </em>
                  (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.ed.ac.uk/clinical-brain-sciences/research/camarades"
                  >
                    CAMARADES
                  </a>
                  ) vem fornecendo treinamento e mentoria àqueles que desejam
                  conduzir revisões sistemáticas e meta-análises de dados de
                  estudos pré-clínicos.
                </p>
                <h2>11.2 SyRF</h2>
                <p>
                  A Plataforma de Revisão Sistemática [{" "}
                  <em>Systematic Review Facility (SyRF)</em>] foi desenvolvida
                  pelo grupo CAMARADES com financiamento inicial do Centro
                  Nacional do Reino Unido para a Substituição, Refinamento e
                  Redução de Animais em Pesquisa (NC3Rs) [
                  <em>
                    UK National Centre for the Replacement, Refinement and
                    Reduction of Animals in Research
                  </em>
                  ] para apoiar a aplicação de técnicas de síntese de evidências
                  em pesquisas pré-clínicas. SyRF é uma plataforma on-line e
                  gratuita para extrair e armazenar dados para revisões
                  sistemáticas de estudos pré-clínicos e fornece recursos
                  educativos e orientação sobre revisões sistemáticas
                  pré-clínicas. A SyRF permite os pesquisadores a colaborar
                  facilmente, gerir os projetos eficientemente, fazer triagens e
                  anotações de estudos para encontrar características
                  importantes, extrair dados de resultados, e adaptar estas
                  etapas a cada projeto. A SyRF foi lançada com recursos mínimos
                  e é necessário um desenvolvimento contínuo para acrescentar
                  funcionalidades orientadas pela procura e feedback do usuário.
                  Qualquer feedback é bem-vindo.
                </p>
                <h2>11.3 Como citar a SyRF?</h2>
                <p>
                  Se você usou a SyRF em qualquer ponto da sua revisão
                  sistemática e meta-análise, por favor cite o nosso artigo de
                  acesso aberto descrevendo as ferramentas da SyRF e o
                  identificador de recursos (RRID):SCR_018907.
                </p>
                <p>
                  Isto nos permite controlar o uso da SyRF e provar a utilidade
                  da plataforma para os financiadores, o que possibilita com que
                  continuemos a fornecer o software da SyRF livre de custos ao
                  usuário.
                </p>
                <p className="font-monospace">
                  Bahor Z, Liao J, Currie G, et al. Development and uptake of an
                  online systematic review platform: the early years of the
                  CAMARADES Systematic Review Facility (SyRF). BMJ Open Science
                  2021;5:e100103. doi:
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://doi.org/10.1136/bmjos-2020-100103"
                  >
                    10.1136/bmjos-2020-100103
                  </a>
                </p>
                <p>
                  Identificador de recursos (Resource Identifier - RRID):
                  SCR_018907
                </p>

                <Link to="/sobre-a-camarades#apoiadores">
                  <h3>11.4 Apoiadores</h3>
                </Link>

                <h3>11.5 Contato</h3>
                <p>
                  Se você tiver dúvidas sobre os recursos, quiser fazer uma
                  pergunta específica ou contribuir para o texto,{" "}
                  <Link to="/contato">entre em contato conosco aqui</Link>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#120">
                <h3 className="fw-bold">FAQ</h3>
                <p>
                  Se você tem dúvidas relacionadas à SyRF que não são abordadas
                  neste guia do usuário, visite a nossa página de perguntas
                  frequentes (FAQ) no site da SyRF.
                </p>
                <p>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://syrf.org.uk/faq"
                  >
                    Encontre as FAQs aqui
                  </a>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Col>
          <Col sm={1}></Col>
        </Row>
      </Tab.Container>
    </section>
  )
}
