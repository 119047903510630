const syrfGuideListGroup = [
  [{ name: "Boas vindas" }],
  [{ name: "Início" }, { name: "Glossário" }],
  [{ name: "Crie uma Conta" }, { name: "" }],
  [{ name: "Participar de um Projeto" }, { name: "" }],
  [{ name: "Criar Novo Projeto" }, { name: "" }],
  [{ name: "Atribuições do usuário SyRF" }, { name: "" }],
  [
    { name: "Navegando pelo Projeto" },
    { name: "Visão geral do projeto" },
    { name: "Detalhes do projeto" },
    { name: "Estudos" },
    { name: "Etapas" },
    { name: "Configuração do projeto" },
  ],
  [
    { name: "Fazendo o upload de uma Busca Sistemática" },
    { name: "Desduplicando sua pesquisa sistemática" },
    { name: "Upload de arquivos" },
    { name: "Upload de PDFs de texto completo" },
    { name: "Ver estudos do projeto" },
    { name: "Excluindo pesquisas sistemáticas" },
  ],
  [
    { name: "Etapas do Projeto" },
    { name: "O que são etapas?" },
    { name: "Adicionar uma etapa" },
    { name: "Etapa com triagem" },
    { name: "Etapa com anotação" },
    { name: "Triagem e anotação numa mesma etapa" },
  ],
  [
    { name: "Triagem" },
    { name: "Triagem usando SyRF " },
    { name: "Número de revisores" },
  ],
  [
    { name: "Questões guia para as anotações" },
    { name: "Criando perguntas de anotação" },
    { name: "Categorias de perguntas" },
    { name: "Criando uma nova pergunta" },
    { name: "Adicionando perguntas às etapas" },
  ],
  [
    { name: "Sobre" },
    { name: "CAMARADES" },
    { name: "SyRF" },
    { name: "Como citar a SyRF?" },
    { name: "Apoiadores" },
    { name: "Contato" },
  ],
  [
    { name: "FAQ" },
    { name: "" }],
]
export default syrfGuideListGroup
