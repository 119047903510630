import * as React from "react"
import GradientJumbo from "../components/gradient-jumbo"
import Layout from "../components/layout"
import Contact from "../components/sections/contact"
import SyRFTabs from "../components/sections/syrf/syrf_tabs"
import Seo from "../components/seo"

const SyrfGuide = props => (
  <Layout language="pt" pathname={props.location.pathname}>
    <Seo
      title="SyRF"
      lang="pt"
      description="Seja bem-vindo(a) ao Guia do Usuário SyRF, criado para lhe auxiliar a usar a plataforma"
    />
    <GradientJumbo subtitle="SyRF" title="Um guia para o SyRF" />
    <SyRFTabs language="pt" />
    <Contact language="pt" />
  </Layout>
)

export default SyrfGuide
